import React from 'react';
import {graphql} from "gatsby";

import Layout from "../../components/layouts/Layout";
import SectionLayoutGrid from "../../components/layouts/SectionLayoutGrid";
import SolutionsHero from "../../components/pages/solutions/SolutionsHero";
import SolutionsHeader from "../../components/pages/solutions/SolutionsHeader";
import {convertCategories} from "../../components/general/content-library/ContentLibraryFunctions";
import BenefitsCard from "../../components/cards/BenefitsCard";
import ContentLibraryCard from "../../components/cards/ContentLibraryCard";
import SolutionComponentsCard from "../../components/cards/SolutionComponentsCard";
import PressPostCard from "../../components/cards/PressPostCard";
import PressPostPinnedCard from "../../components/cards/PressPostPinnedCard";

import Col from "../../components/styled/grid/Col";
import {Header2} from "../../components/styled/typography/Typography";
import {ButtonContainer, ButtonLink} from "../../components/styled/button/ButtonComponents";
import {OnlyDesktop, OnlyMobile} from "../../components/styled/views/MobileOrDesktop";

const SolutionsTemplate = ({data}) => {

    const {solution, webinar, webinar1, blog, related1, related2, related3, related4} = data;

    return (
        <Layout lang={solution.locale} seo={solution.SEO}>
            <SolutionsHero
                description={solution.hero.description}
                button={solution.hero.button}
                image={solution.hero.image.url}
                title_color={solution.hero.title_color}
                title_white={solution.hero.title_white}
            />
            <SolutionsHeader
                image={solution.challenges.image.url}
                description={solution.challenges.description}
                title={solution.challenges.title}
                id={"challenges"}
                cards={solution.challenges.cards}
                subtitle={solution.challenges.subtitle}
                button={solution.challenges.button}
            />
            <SectionLayoutGrid id={"challenges_related"} customBackground="middle" customMinHeight="medium">
                <Col>
                    <Header2 color="white">
                        {solution.related_section1_title}
                    </Header2>
                </Col>
                {webinar && !webinar1 &&
                <Col span={8} grid>
                    <OnlyDesktop>
                        <PressPostPinnedCard
                            title={webinar.title}
                            avatar={webinar.avatar.url}
                            slug={webinar.slug}
                            short_description={webinar.short_description}
                            date={webinar.date}
                        />
                    </OnlyDesktop>
                    <OnlyMobile>
                        <PressPostCard
                            title={webinar.title}
                            avatar={webinar.avatar.url}
                            slug={webinar.slug}
                            short_description={webinar.short_description}
                            date={webinar.date}
                        />
                    </OnlyMobile>
                </Col>
                }
                {webinar1 && !webinar &&
                <Col span={8} grid>
                    <ContentLibraryCard
                        isSmall
                        title={webinar1.title}
                        avatar={webinar1.avatar.url}
                        category={convertCategories(solution.locale, webinar1.filters.category)}
                        ss_link={webinar1.ss_tracking_link}
                        gated={webinar1.gated_content}
                        slug={webinar1.gated_content ? "/" + webinar1.filters.lang + "/content-library/" + webinar1.slug : undefined}
                    />
                </Col>
                }
                {blog &&
                <Col span={4} grid>
                    <PressPostCard
                        title={blog.title}
                        avatar={blog.avatar.url}
                        slug={"/blogs/" + blog.slug}
                        date={blog.date}
                        locale={solution.locale}
                        short_description={blog.short_description}
                    />
                </Col>
                }
            </SectionLayoutGrid>
            <SolutionsHeader
                image={solution.help.image.url}
                description={solution.help.description}
                title={solution.help.title}
                id={"help"}
                cards={solution.help.cards}
                subtitle={solution.help.subtitle}
                button={solution.help.button}
            />
            <SectionLayoutGrid id={"benefits_related"} customBackground="middle" customMinHeight={"small"}>
                <Col>
                    <Header2 color="white">
                        {solution.locale === "hu" ? "Előnyök" : "Benefits"}
                    </Header2>
                </Col>
                {solution.benefits && solution.benefits.map((item) => (
                    <BenefitsCard
                        key={item.id}
                        title={item.title}
                        avatar={item.avatar.url}
                    />
                ))}
                <Col marginTop="medium">
                    <Header2 color="white">
                        {solution.locale === "hu" ? "A megoldás elemei" : "Solution components"}
                    </Header2>
                </Col>
                {solution.solution_components && solution.solution_components.map((item) => (
                    <Col key={item.id} span={4} grid>
                        <SolutionComponentsCard
                            title={item.title}
                            avatar={item.logo.url}
                            slug={item.url}
                        />
                    </Col>
                ))}
                <Col marginTop="large">
                    <Header2 color="white">
                        {solution.related_section2_title}
                    </Header2>
                </Col>
                {related1 &&
                <Col span={6}>
                    <ContentLibraryCard
                        isSmall
                        title={related1.title}
                        avatar={related1.avatar.url}
                        category={convertCategories(solution.locale, related1.filters.category)}
                        ss_link={related1.ss_tracking_link}
                        gated={related1.gated_content}
                        slug={related1.gated_content ? "/" + related1.filters.lang + "/content-library/" + related1.slug : undefined}
                    />
                </Col>
                }
                {related2 &&
                <Col span={6}>
                    <ContentLibraryCard
                        isSmall
                        title={related2.title}
                        avatar={related2.avatar.url}
                        category={convertCategories(solution.locale, related2.filters.category)}
                        ss_link={related2.ss_tracking_link}
                        gated={related2.gated_content}
                        slug={related2.gated_content ? "/" + related2.filters.lang + "/content-library/" + related2.slug : undefined}
                    />
                </Col>
                }
                {related3 &&
                <Col span={6}>
                    <ContentLibraryCard
                        isSmall
                        title={related3.title}
                        avatar={related3.avatar.url}
                        category={convertCategories(solution.locale, related3.filters.category)}
                        ss_link={related3.ss_tracking_link}
                        gated={related3.gated_content}
                        slug={related3.gated_content ? "/" + related3.filters.lang + "/content-library/" + related3.slug : undefined}
                    />
                </Col>
                }
                {related4 &&
                <Col span={6}>
                    <ContentLibraryCard
                        isSmall
                        title={related4.title}
                        avatar={related4.avatar.url}
                        category={convertCategories(solution.locale, related4.filters.category)}
                        ss_link={related4.ss_tracking_link}
                        gated={related4.gated_content}
                        slug={related4.gated_content ? "/" + related4.filters.lang + "/content-library/" + related4.slug : undefined}
                    />
                </Col>
                }
                <Col grid center>
                    <ButtonContainer>
                        <ButtonLink to={solution.locale === "hu" ? "/hu/content-library/" : "/en/content-library/"}
                                    color="primary-brand">
                            {solution.locale === "hu" ? "Tovább a Tudástárhoz" : "See all in Content Library"}
                        </ButtonLink>
                        {solution && solution.slug.includes("zero-trust") &&
                        <ButtonLink
                            to={solution.locale === "hu" ? "/hu/forms/request-trial" : "/en/forms/request-trial"}
                            color="secondary-brand"
                            state={{
                                product: "Zero Trust",
                            }}
                        >
                            {solution.locale === "hu" ? "Szeretne egy ZT auditot?" : "Request a Zero Trust audit?"}
                        </ButtonLink>
                        }
                    </ButtonContainer>
                </Col>
            </SectionLayoutGrid>
        </Layout>
    );
};

export const query = graphql`
   query GetSingleSolutionsTemplate($locale: String, $slug: String, $webinar_slug: String, $blog_slug: String, $related1_slug: String, $related2_slug: String, $related3_slug: String, $related4_slug: String) {
         solution: strapiSolutionsTemplates(locale: {eq: $locale}, slug: {eq: $slug}) {
              locale
              slug
              hero {
                title_white
                title_color
                description
                button {
                  link
                  name
                }
                image {
                  url
                }
              }
              SEO {
                title
                isIndexable
                description
                keywords
                preview {
                  url
                }
              }
              related_section1_title
              related_section2_title
              benefits {
                title
                id
                avatar {
                  url
                }
              }
              solution_components {
                url
                title
                id
                logo {
                  url
                }
              }
              blog_slug
              challenges {
                title
                subtitle
                description
                image {
                  url
                }
                cards {
                  description
                  id
                  title
                }
                button {
                  link
                  name
                }
              }
              help {
                title
                subtitle
                image {
                  url
                }
                description
                id
                cards {
                  description
                  id
                  title
                }
                button {
                  link
                  name
                }
              }
              webinar_slug
              related1_slug
              related2_slug
              related3_slug
              related4_slug
         }
         
         webinar: strapiPressPosts(slug: {eq: $webinar_slug}) {
            avatar {
              url
            }
            date(formatString: "LL", locale: $locale)
            slug
            short_description
            title
         }
         
         webinar1: strapiContentLibraryDownloads(slug: {eq: $webinar_slug}) {
              products {
                name
                slug
              }
              filters {
                category
                lang
                theme
              }
              title
              ss_tracking_link
              date
              slug
              gated_content {
                list_token
              }
              avatar {
                  url
              }
         }
         
         blog: strapiBlogposts(slug: {eq: $blog_slug}) {
            short_description
            slug
            title
            date(formatString: "LL", locale: $locale)
            avatar {
              url
            }
         }
         
         related1: strapiContentLibraryDownloads(slug: {eq: $related1_slug}) {
              products {
                name
                slug
              }
              filters {
                category
                lang
                theme
              }
              title
              ss_tracking_link
              date
              slug
              gated_content {
                list_token
              }
              avatar {
                  url
              }
         }
         
         related2: strapiContentLibraryDownloads(slug: {eq: $related2_slug}) {
              products {
                name
                slug
              }
              filters {
                category
                lang
                theme
              }
              title
              ss_tracking_link
              date
              slug
              gated_content {
                list_token
              }
              avatar {
                  url
              }
         }
         
         related3: strapiContentLibraryDownloads(slug: {eq: $related3_slug}) {
              products {
                name
                slug
              }
              filters {
                category
                lang
                theme
              }
              title
              ss_tracking_link
              date
              slug
              gated_content {
                list_token
              }
              avatar {
                  url
              }
         }
         
         related4: strapiContentLibraryDownloads(slug: {eq: $related4_slug}) {
              products {
                name
                slug
              }
              filters {
                category
                lang
                theme
              }
              title
              ss_tracking_link
              date
              slug
              gated_content {
                list_token
              }
              avatar {
                  url
              }
         }
   }
 `

export default SolutionsTemplate;