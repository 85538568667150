import React from 'react';

import {Header3} from "../styled/typography/Typography";
import {
    SolutionComponentsCardImg,
    SolutionComponentsCardWrapper
} from "../styled/cards/SolutionComponentsCardComponents";

const SolutionComponentsCard = ({title, avatar, slug}) => {
    return (
        <SolutionComponentsCardWrapper to={slug}>
            {avatar && <SolutionComponentsCardImg src={avatar} alt={title}/>}
            {title && <Header3 textAlign="center" color="white" small>{title}</Header3>}
        </SolutionComponentsCardWrapper>
    );
};

export default SolutionComponentsCard;