import React from 'react';

import SectionLayoutGrid from "../../layouts/SectionLayoutGrid";

import Col from "../../styled/grid/Col";
import {Body, Span, Title} from "../../styled/typography/Typography";
import {HeroTextWrapper, HeroWrapper} from "../../styled/pages/solutions/SolutionsComponents";
import {ButtonLink} from "../../styled/button/ButtonComponents";

const SolutionsHero = ({image, title_white, title_color, description, button}) => {
    return (
        <HeroWrapper>
            <SectionLayoutGrid image={image} customBackground="hero"
                               id="start" solutionsMobile>
                <Col span={7}>
                    <Title color="white">
                        {title_white} <Span color="primary-brand">
                        {title_color}
                    </Span>
                    </Title>
                </Col>
                <Col span={5}/>
                <Col>
                    <HeroTextWrapper>
                        <Body color="body-text">
                            {description}
                        </Body>
                    </HeroTextWrapper>
                </Col>
                <Col>
                    <ButtonLink to={button.link} color="primary-brand">
                        {button.name}
                    </ButtonLink>
                </Col>
            </SectionLayoutGrid>
        </HeroWrapper>
    );
};

export default SolutionsHero;