import React from 'react';

import {
    PressPostPinnedCardImg,
    PressPostPinnedCardWrapper, PressPostPinnedDescriptionWrapper, PressPostPinnedTextWrapper,
    PressPostPinnedTitleWrapper
} from "../styled/cards/PressCardComponents";
import {Body, Header3} from "../styled/typography/Typography";

const PressPostPinnedCard = ({avatar, title, short_description, slug, date}) => {
    return (
        <PressPostPinnedCardWrapper to={"/press/" + slug}>
            <PressPostPinnedCardImg avatar={avatar}/>
            <PressPostPinnedTextWrapper>
                <PressPostPinnedTitleWrapper>
                    <Header3 color="white" marginBottom="smaller">
                        {title}
                    </Header3>
                    <Body color="white" textAlign="right" bold>
                        {date}
                    </Body>
                </PressPostPinnedTitleWrapper>
                <PressPostPinnedDescriptionWrapper>
                    <Body color="body-text">
                        {short_description}
                    </Body>
                </PressPostPinnedDescriptionWrapper>
            </PressPostPinnedTextWrapper>
        </PressPostPinnedCardWrapper>
    );
};

export default PressPostPinnedCard;