import styled from "styled-components";
import {Link} from "gatsby";

export const SolutionComponentsCardWrapper = styled(Link)`
  display: grid;
  align-self: stretch;
  align-items: start;
  padding: 2rem;
  grid-row-gap: 2rem;
  background-color: #173f6c;
  box-shadow: 1rem 1rem 3rem rgba(0, 0, 0, 0.1);
  transition: box-shadow 500ms;
  grid-template-rows: auto 1fr;
  cursor: pointer;

  border-bottom: solid 0.25rem #00aeef;

  &:hover {
    box-shadow: 0 0 1rem 0 #4fa0e2;
  }
`

export const SolutionComponentsCardImg = styled.img`
  justify-self: center;
  height: 4rem;
  width: 100%;
  max-width: 12rem;
`